import { ref } from "vue";
import { saveAs } from "file-saver";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { activeActivity } from "@/plugins/i18n";

export const useDownload = () => {
  const loading = ref(false);
  const toast = useToast();
  const { t } = useI18n();
  const downloadFile = async (folder: string, fileName: string) => {
    try {
      const id = activeActivity.value.id;
      const response = await fetch(
        `${process.env.VUE_APP_API}api/file/${id}/${folder}/${fileName}?type=1`
      );
      if (!response.ok) {
        toast.add({
          severity: "error",
          detail: t("connexionError"),
          life: parseInt(process.env.VUE_APP_TOAST_LIFE),
        });
      } else {
        const blob = await response.blob();
        saveAs(blob, fileName);
        toast.add({
          severity: "success",
          detail: t("download_success"),
          life: parseInt(process.env.VUE_APP_TOAST_LIFE),
        });
      }
    } catch (error) {
      toast.add({
        severity: "error",
        detail: t("unknownError"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  };
  return { loading, downloadFile };
};
