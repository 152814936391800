import { useValidation } from "vue3-form-validation";
import { FileUploadSelectEvent } from "primevue/fileupload";
import { useFunctions } from "../function/useFunctions";
import { EmployeeModelFragment } from "@/graphql/types";
import { useSectors } from "../sector/useSectors";

export const useEmployeeForm = () => {
  const { functions, loading: _functionsLoading } = useFunctions();
  const { sectors, loading: _sectorsLoading } = useSectors();
  const {
    form,
    hasError,
    validateFields: validate,
    resetFields: reset,
  } = useValidation({
    lastName: {
      $value: "",
      $rules: [(n: string) => !n && ""],
    },
    firstName: {
      $value: "",
    },
    functionId: {
      $value: null,
      $rules: [(value: number) => !value && ""],
    },
    sectorId: {
      $value: null,
    },
    contacts: {
      $value: "",
    },
    entryDate: {
      $value: null,
    },
    departureDate: {
      $value: null,
    },
    identity: {
      $value: null,
    },
    cv: {
      $value: null,
    },
  });
  const setCV = (event: FileUploadSelectEvent) => {
    form.cv.$value = event.files.at(0);
  };
  const removeCv = () => (form.cv.$value = null);
  const setIdentity = (event: FileUploadSelectEvent) => {
    form.identity.$value = event.files.at(0);
  };
  const removeIdentity = () => (form.identity.$value = null);

  const validateFields = async () => {
    const input = await validate();
    return {
      ...input,
      entryDate: form.entryDate.$value,
      departureDate: form.departureDate.$value,
      cv: form.cv.$value,
      identity: form.identity.$value,
    };
  };

  const resetFields = (data?: EmployeeModelFragment) => {
    reset({
      lastName: data?.lastName,
      firstName: data?.firstName,
      functionId: data?.function?.id,
      sectorId: data?.sector?.id,
      contacts: data?.contacts,
    });
    form.entryDate.$value = data?.entryDate ? new Date(data.entryDate) : null;
    form.departureDate.$value = data?.departureDate
      ? new Date(data.departureDate)
      : null;
  };

  return {
    setIdentity,
    setCV,
    removeIdentity,
    validateFields,
    removeCv,
    resetFields,
    form,
    hasError,
    functions,
    _functionsLoading,
    sectors,
    _sectorsLoading
  };
};
