import {
  CreateFunctionInput,
  EmployeeModelFragment,
  FunctionModelFragment,
  useUpdateEmployeeMutation,
} from "@/graphql/types";
import { trim } from "@/graphql/utils/utils";
import { useToast } from "primevue/usetoast";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

export const useUpdateEmployee = () => {
  const visible = ref(false);
  const toast = useToast();
  const { t } = useI18n();
  const id = ref<number>(0);
  const refUpdateEmployee = ref<any>(null);
  const { loading, mutate, onError, onDone } = useUpdateEmployeeMutation({
    context: { hasUpload: true },
  });
  const disableSubmit = computed(
    () => refUpdateEmployee.value?.hasError.value || loading.value
  );

  onDone(({ errors }) => {
    if (errors) {
      toast.add({
        severity: "warn",
        summary: t("update.title"),
        detail: t("update.failed"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else {
      toast.add({
        severity: "success",
        summary: t("update.title"),
        detail: t("update.success"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
    visible.value = false;
  });

  onError(() => {
    toast.add({
      severity: "warn",
      summary: t("update.title"),
      detail: t("update.failed"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  const close = () => (visible.value = false);
  const openUpdate = (data: EmployeeModelFragment) => {
    id.value = data.id;
    visible.value = true;
    setTimeout(() => {
      refUpdateEmployee.value?.resetFields(data);
    }, 100);
  };

  const onSubmit = (input: CreateFunctionInput) => {
    trim(input);
    void mutate({
      input: {
        ...input,
        id: id.value,
      },
    });
  };

  function submit() {
    refUpdateEmployee.value?.validateFields().then(onSubmit);
  }

  return {
    disableSubmit,
    loading,
    visible,
    close,
    submit,
    openUpdate,
    refUpdateEmployee,
    onSubmit,
  };
};
