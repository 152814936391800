import { useSectorsQuery } from "@/graphql/types";
import { activeActivity } from "@/plugins/i18n";
import { computed, ref } from "vue";

export const useSectors = () => {
  const filter = ref({});
  const { loading, result } = useSectorsQuery(() => ({
    activityId: activeActivity.value.id,
  }));
  const sectors = computed(() => result.value?.sectors || []);

  return { loading, sectors, filter };
};
