import { useFunctionsQuery } from "@/graphql/types";
import { activeActivity } from "@/plugins/i18n";
import { FilterMatchMode } from "primevue/api";
import { computed, ref } from "vue";

export const useFunctions = () => {
  const filter = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const { loading, result } = useFunctionsQuery(() => ({
    activityId: activeActivity.value.id,
  }));
  const functions = computed(() => result.value?.functions || []);

  return { loading, functions, filter };
};
