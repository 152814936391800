import { computed, ref } from "vue";
import { useCreateEmployeeMutation } from "@/graphql/types";
import { useToast } from "primevue/usetoast";
import { trim } from "@/graphql/utils/utils";
import { useI18n } from "vue-i18n";

export const useCreateEmployee = () => {
  const visible = ref(false);
  const refCreateEmployee = ref<any>(null);
  const toast = useToast();
  const { t } = useI18n();
  const { loading, mutate, onDone, onError } = useCreateEmployeeMutation({
    context: { hasUpload: true },
    update: (cache, { data }) => {
      if (data?.createEmployee) {
        cache.modify({
          fields: {
            employees(value, { toReference }) {
              return [toReference(data.createEmployee), ...value];
            },
          },
        });
      }
    },
  });
  const disableSubmit = computed(
    () => refCreateEmployee.value?.hasError?.value || loading.value
  );

  onDone(({ errors }) => {
    toggle();
    if (errors) {
      toast.add({
        severity: "warn",
        summary: t("create.title"),
        detail: t("create.failed"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else refCreateEmployee.value?.resetFields();
  });

  onError((err) => {
    console.log(err?.message);
    toast.add({
      severity: "warn",
      summary: t("create.title"),
      detail: t("create.failed"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  const toggle = () => (visible.value = !visible.value);

  function submit() {
    refCreateEmployee.value?.validateFields().then(async (input) => {
      trim(input);
      void mutate({ input });
    });
  }

  return {
    refCreateEmployee,
    disableSubmit,
    loading,
    visible,
    toggle,
    submit,
  };
};
